<template>
<div>
<vue-headful :title="title"/>
  <v-slide-x-transition appear>
 <v-container 
        fill-height
        white--text
        class="top"
      >
      <v-row>
        <v-col
               cols="12"
               lg="12"
                align="center"
                justify="center"
              >
                <v-form  @submit.prevent="buscar">
                  <v-row>
                      <v-col
                        cols="12"
                        lg="10"
                        md="8"
                      >
                        <v-text-field
                         dark
                          label="Escriba el dominio que desea buscar"
                          outlined
                          clearable
                          filled
                          v-model="dominio"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        class="d-flex"
                        cols="12"
                        lg="2"
                        md="12"
                        >
                        <v-btn
                        class="mr-2 buscar"
                        color="#003791"
                        elevation="9"
                        large
                        :disabled="loading"
                        v-on:click="buscar"
                        >Buscar
                          <v-progress-circular
                              v-if="loading"
                              indeterminate
                              color="primary"
                          ></v-progress-circular>
                          <span v-if="!loading"> </span>
                        </v-btn>
                    </v-col>  
                </v-row>
           </v-form>
      </v-col>
      </v-row>
 </v-container>
    </v-slide-x-transition>
 <v-container>
   <template v-if="notFound === false">
     <v-container>
       <v-row>
           <v-col cols="12"
                    lg="12"
           >
            <h1 class="display-1 mt-5 text-center"
                v-for="(item , i) in items"
                :key="i"
            >{{item.text}}</h1>
           </v-col>
           <v-col cols="12"
                    lg="12"
                    v-model="items"
                    v-for="(item, i) in items"
                    :key="('A'+i)"
           >
            <h3 class="negrita">{{item.subtitle}}</h3>
           </v-col>
          <v-divider></v-divider>
       </v-row>
           <v-simple-table>
            <template v-slot:default v-if="data.datos">
               <thead>
                <tr>
                <th class="text-left">
                 Empresa
                </th>
                <th class="text-center"
                >
              Fecha de Expiración de Dominio
                </th>
                <th class="text-center"
                >
               Dirección de Empresa
                </th>
                </tr>
               </thead>
               <tbody>
              <tr>
                <td>{{ data.datos.cliente }}</td>
                <td class="text-center">{{ data.datos.fechaExpiracion }}</td>
                <td class="text-center">{{ data.datos.direccion }}</td>
              </tr>
            </tbody>
            </template>
        </v-simple-table>
        <v-row>
          <v-col cols="12"
                    lg="12"
                    v-model="items"
                    v-for="(item, i) in items"
                    :key="('A'+i)">
            <h3 class="negrita mt-8">{{item.subtitle2}}</h3>
           </v-col>
        </v-row>
         <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left"
                >
                Nombre
                </th>
                <th class="text-center"
                >
            Correo Electrónico
                </th>
                <th class="text-center"
                >
            Nivel de Dominio
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td >{{ data.contactos.nombre }}</td>
                <td class="text-center">{{ data.contactos.correoElectronico[0].value }}</td>
                <td class="text-center">{{ data.contactos.telefono }}</td>
              </tr>
            </tbody>
          </template>
    </v-simple-table>
</v-container>
   </template>
   <template v-else>
     <div class="row">
       <v-col cols="12"
                    lg="12"
           >
            <h1 class="display-1 mt-5 text-center"
                v-for="(item , i) in items"
                :key="i"
            >{{notFoundMessage.mensaje}}</h1>
           </v-col>
     </div>
   </template>
 </v-container>
</div>
</template>
<script>
import vueHeadful from 'vue-headful';
export default {
  components:{
    vueHeadful
  },
      data: () =>({
        title:'WhoIs',
        items:[
            {
                'text':'Resultado de la búsqueda WhoIs',
                'subtitle':'Datos de Empresa',
                'subtitle2':'Información de Contacto'
            }
        ],
        data:[
        ],
        loading: false,
        dominio : '',
        items2:['.ni','.com','.edu','.net'],
        notFound : true,
        notFoundMsg : '',
        searched : false
    }),
    computed : {
      notFoundMessage : {
        get() {  
            return this.notFoundMsg;       
        }
      }
    }
    ,
   methods:{
      buscar: async function() {
      this.loading = true;
      await this.$http.get(this.$keys('WHO_IS')+'?dominio='+this.dominio)
      .then(resp=>{
        if(resp.status == 200)
        {
            let fechaFin = resp.data.datos.fechaExpiracion.split("T", 3);
            resp.data.datos.fechaExpiracion = fechaFin[0];
            this.data = resp.data;
            this.notFound = false;
            this.loading = false;
        }
      }).catch(err => {
            this.notFound = true;
            this.notFoundMsg = JSON.parse(err.response.data.mensaje);
            this.loading = false;
      });
    }
   }
}
</script>
<style >
.top{
  margin-top: -160px !important;
}
@media (max-width: 725px){
  .top{
  margin-top: -250px !important;
}
.buscar{
  margin-top: -20px !important;
  text-align: center !important;
}
}
.negrita{
    font-weight: 600 !important;
    line-height: 43px !important;
    font-style: bold !important;
    color: #000000 !important;
    font-size: 30px;
}
.fullwith{
    margin-top: -20px;
}
@media (max-width: 460px ) {
  .v-application .display-2 {
    margin-top: 80px !important;
  }
  .v-application .title{
    display: none;
  }
  .v-btn:not(.v-btn--round).v-size--large {
    height: 55px;
    min-width: 55px !important;
    padding: 0 19.5555555556px;
    margin-left: 5px;
    color: #fff;
   
}
}
.theme--light.v-label {
    color: #fff !important;
}
.v-btn:not(.v-btn--round).v-size--large {
    height: 55px;
    min-width: 78px;
    padding: 0 19.5555555556px;
    margin-left: 5px;
    color: #fff;
}
 .theme--light.v-input input, .theme--light.v-input textarea{
   color: #fff !important;
  
 }
 .theme--light.v-select .v-select__selection--comma {
    color: #fff !important;
}
.mdi-menu-down::before {
    content: "\F035D";
    color: #fff;
}

.v-list-item__title {
  
    color: #000 !important;
}
.decoracion{
  text-decoration: none !important;
}
</style>
